import { combineReducers } from '@reduxjs/toolkit';
import { reducer as testReducer } from 'slices/test';

const rootReducer = combineReducers({
  tender: testReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
