import { IObject } from 'types/object';

export function handleOnClickInfoMenuItem(
  props: IObject,
  activeTab: number
): void {
  const { state, setState } = props;
  const { isOpenRightAside } = state;

  if (!isOpenRightAside) {
    setState({
      ...state,
      isOpenRightAside: true,
      activeTabRightAside: activeTab,
    });
  } else {
    setState({ ...state, activeTabRightAside: activeTab });
  }
}

export function handleOnClickTradeMenuItem(props: IObject, path: string): void {
  const { history } = props;
  history.push(path);
}
