import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    marginBottom: '24px',
  },
}));

export default function Notifications(): ReactElement {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Уведомления</h3>
    </div>
  );
}
