import React, { ComponentType, ReactElement } from 'react';
import { IObject } from 'types/object';

const withHandlers = <P extends IObject>(handlers: P) => (
  WrappedComponent: ComponentType<P>
) => (props: P): ReactElement => {
  const handlersProps = Object.entries(handlers).reduce((acc, [key, func]) => {
    return { ...acc, [key]: func(props) };
  }, {});
  const newProps = { ...props, ...handlersProps };

  return <WrappedComponent {...newProps} />;
};

export default withHandlers;
