import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL } from 'config';

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 3000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);

// eslint-disable-next-line
export const axiosRequest = (options: AxiosRequestConfig): any => {
  return axiosInstance.request(options);
};
