import React, { ReactElement } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { isLightTheme } from 'utils/theme';
import getMixin from 'utils/mixins';
import IconButton from 'components/IconButton';
import { PRIMARY_COLOR } from 'constants/colors';
import { tabIcons } from 'views/asides/right/config';
import { IObject } from 'types/object';

const useStyles = makeStyles((theme) => ({
  container: {
    ...getMixin('flexRow', theme),
    '&.group': {
      '& button': {
        marginRight: 10,
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  },
}));

const useAppBarStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: () =>
      isLightTheme(theme) ? theme.palette.grey[200] : theme.palette.grey.A400,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  positionFixed: {
    top: 0,
  },
}));

const useToolBarStyles = makeStyles((theme) => ({
  root: {
    height: 54,
    ...getMixin('flexRow', theme),
    justifyContent: 'space-between',
    paddingLeft: '16px !important',
    paddingRight: '16px !important',
  },
}));

export default function Navbar(props: IObject): ReactElement {
  const { state, setState, onOpenMenu } = props;
  const styles = useStyles();
  const appBarStyles = useAppBarStyles();
  const toolBarStyles = useToolBarStyles();

  return (
    <AppBar position="fixed" classes={appBarStyles}>
      <Toolbar classes={toolBarStyles}>
        <div className={styles.container}>
          <IconButton
            isMergeStyles
            size="small"
            icon="burger"
            iconProps={{ htmlColor: 'black' }}
            iconHoverColor={PRIMARY_COLOR}
            onClick={onOpenMenu}
          />
        </div>
        <div className={clsx(styles.container, 'group')}>
          {tabIcons.map((item) => {
            const { icon, iconHoverColor, onClick, id } = item;

            return (
              <IconButton
                isMergeStyles
                size="small"
                icon={icon}
                iconHoverColor={iconHoverColor}
                onClick={onClick({ state, setState })}
                iconProps={{ htmlColor: 'black' }}
                key={id}
              />
            );
          })}
        </div>
      </Toolbar>
    </AppBar>
  );
}
