import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton as Button } from '@material-ui/core';
import getIcon from 'utils/icons';
import { IObject } from 'types/object';
import merge from 'deepmerge';

interface IIconBtnProps {
  iconProps?: IObject;
  icon?: string;
  iconHoverColor?: string;
  classes?: IObject;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  isMergeStyles?: boolean;
  size?: 'small' | 'medium';
}

interface IStylesProps {
  iconHoverColor?: string;
}

const IconButtonStyles = {
  root: {
    '&:hover': {
      '& svg': {
        fill: ({ iconHoverColor }: IStylesProps) => iconHoverColor || '',
      },
    },
  },
};

export default function IconButton(props: IIconBtnProps): ReactElement {
  const {
    onClick,
    iconProps = {},
    classes = {},
    isMergeStyles = false,
    icon = '',
    iconHoverColor = '',
    ...other
  } = props;
  const styles = isMergeStyles
    ? makeStyles(merge(IconButtonStyles, classes))({
        iconHoverColor,
      })
    : classes;
  return (
    <Button onClick={onClick} classes={styles} {...other}>
      {getIcon(icon, { ...iconProps })}
    </Button>
  );
}
