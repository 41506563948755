export default function uniqueId(): string {
  const idStrLen = 32;
  let idStr = `${(Math.floor(Math.random() * 25) + 10).toString(36)}_`;
  idStr += `${new Date().getTime().toString(36)}_`;
  do {
    idStr += Math.floor(Math.random() * 35).toString(36);
  } while (idStr.length < idStrLen);

  return idStr;
}
