import type {
  IAuthState,
  Action,
  LoginAction,
  RegisterAction,
  ActiveAction,
} from 'types/auth';

function setUserData(state: IAuthState, action: ActiveAction): IAuthState {
  const { user } = action.payload;

  return {
    ...state,
    isAuthenticated: true,
    user,
  };
}

export default function reducer(state: IAuthState, action: Action): IAuthState {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      return setUserData(state, action as LoginAction);
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      return setUserData(state, action as RegisterAction);
    }
    default: {
      return { ...state };
    }
  }
}
