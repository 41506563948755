import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isLightTheme } from 'utils/theme';
import clsx from 'clsx';

interface IAppBackground {
  children: ReactNode | ReactNode[];
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    background: () =>
      isLightTheme(theme)
        ? 'url("/backgrounds/bg-white.png") repeat'
        : 'url("/backgrounds/bg-black.png") repeat',
    padding: '15px 84px',
    overflow: 'hidden',
  },
}));

const AppBackground = React.forwardRef<HTMLDivElement, IAppBackground>(
  (props, ref) => {
    const { children, className } = props;
    const styles = useStyles();

    return (
      <div ref={ref} className={clsx(styles.root, className)}>
        {children}
      </div>
    );
  }
);

export default AppBackground;
