import React, { ReactElement } from 'react';
import { IObject } from 'types/object';
import { makeStyles } from '@material-ui/core/styles';
import AsideBar from 'components/AsideBar';
import MainMenu from 'views/main-menu';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      minWidth: 0,
      boxShadow: 'none',
      zIndex: theme.zIndex.tooltip + 300,
    },
  },
}));

export default function LeftAside(props: IObject): ReactElement {
  const styles = useStyles();

  return (
    <AsideBar direction="left" className={styles.root}>
      <MainMenu {...props} />
    </AsideBar>
  );
}
