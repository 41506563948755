import React, { cloneElement, Fragment, ReactElement } from 'react';
import { IObject } from 'types/object';
import uniqueId from 'utils/uuid';

interface IRecursiveProps {
  data: Array<IObject>;
  wrappers: IObject;
  Component: ReactElement;
  level?: number;
}

export default function Recursive(props: IRecursiveProps): ReactElement {
  const { data, wrappers, Component, level = 1 } = props;
  const newLevel = level + 1;

  return (
    <>
      {data.map((item) => {
        const { wrapper, children, element, id } = item;

        if (children) {
          const Wrapper = wrappers[wrapper];

          if (wrapper && !element) {
            return cloneElement(
              Wrapper,
              { node: item, key: id },
              <Recursive {...props} level={newLevel} data={children} />
            );
          }

          return (
            <Fragment key={id}>
              {cloneElement(Component, {
                ...Component.props,
                node: item,
              })}
              {cloneElement(
                Wrapper,
                { node: item, id: uniqueId() },
                <Recursive {...props} level={newLevel} data={children} />
              )}
            </Fragment>
          );
        }

        return cloneElement(Component, {
          ...Component.props,
          node: item,
          key: id,
        });
      })}
    </>
  );
}
