import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IObject } from 'types/object';
import { isLightTheme } from 'utils/theme';
import IconButton from 'components/IconButton';
import { ITabData } from './config';

interface IRightAsideButtonsProps {
  data: Array<ITabData>;
  state?: IObject;
  setState?: (state: IObject) => void;
  history?: IObject;
}

const useStyles = makeStyles({
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .icon-button': {
      marginBottom: 24,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
});

const useIconStyles = makeStyles((theme) => ({
  root: {
    fill: isLightTheme(theme) ? '#000' : theme.palette.text.secondary,
  },
}));

const IconButtonStyles = {
  root: {
    width: 24,
    height: 24,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export default function RightAsideButtons(
  props: IRightAsideButtonsProps
): ReactElement {
  const { data = [], state, setState, history } = props;
  const styles = useStyles();
  const iconStyles = useIconStyles();

  return (
    <div className={styles.inner}>
      {data.map((item) => {
        const { onClick, icon, iconHoverColor, id } = item;

        return (
          <IconButton
            classes={IconButtonStyles}
            isMergeStyles
            className="icon-button"
            onClick={onClick({ state, setState, history })}
            icon={icon}
            iconHoverColor={iconHoverColor}
            iconProps={{ classes: iconStyles }}
            key={id}
          />
        );
      })}
    </div>
  );
}
