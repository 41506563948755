import React, { ReactElement, useEffect, useState } from 'react';
import { IObject } from 'types/object';
import { Tabs as TabsContainer } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import uniqueId from 'utils/uuid';
import TabPanel from './TabPanel';

interface ITabsProps {
  tabs?: Array<string>;
  panels: Array<ReactElement>;
  tabClasses?: IObject;
  tabPanelClassName?: string;
  tabItemClasses?: IObject;
  disabledTabs?: Array<boolean>;
  activeTab?: number;
}

export default function Tabs(props: ITabsProps): ReactElement {
  const {
    tabs = [],
    panels,
    tabClasses,
    tabPanelClassName,
    tabItemClasses,
    disabledTabs = [],
    activeTab = 0,
  } = props;

  const [activeTabIndex, setActiveTabIndex] = useState<number>(activeTab);

  useEffect(() => {
    setActiveTabIndex(activeTab);
  }, [activeTab]);

  function a11yProps(index: number): IObject {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function handleOnChangeTab(
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void {
    setActiveTabIndex(newValue);
  }

  return (
    <>
      <TabsContainer
        value={activeTabIndex}
        onChange={handleOnChangeTab}
        classes={tabClasses}
      >
        {tabs.map((title, i) => {
          return (
            <Tab
              label={title}
              {...a11yProps(i)}
              classes={tabItemClasses}
              disabled={disabledTabs[i]}
              key={uniqueId()}
            />
          );
        })}
      </TabsContainer>

      {panels.map((Panel, i) => {
        return (
          <TabPanel
            activeTabIndex={activeTabIndex}
            index={i}
            className={tabPanelClassName}
            key={uniqueId()}
          >
            {Panel}
          </TabPanel>
        );
      })}
    </>
  );
}
