import { useEffect, useState, RefObject } from 'react';

type TClientSize = {
  width: number;
  height: number;
};

const initSize = {
  width: -1,
  height: -1,
};

export default function useClientSize(
  ref: RefObject<HTMLElement>
): TClientSize {
  const [clientSize, setClientSize] = useState<TClientSize>(initSize);

  function getSize(): void {
    const root = ref.current;
    if (root) {
      root.style.width = '100%';
      root.style.height = '100%';
      const width = root.clientWidth;
      const height = root.clientHeight;
      root.removeAttribute('style');
      setClientSize({ width, height });
    }
  }

  useEffect(() => {
    getSize();
    window.addEventListener('resize', getSize);

    return () => {
      return window.removeEventListener('resize', getSize);
    };
  }, []);

  return clientSize;
}
