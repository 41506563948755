import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AsideBar from 'components/AsideBar';
import { IObject } from 'types/object';
import Tabs from 'components/tabs/Tabs';
import Notifications from 'views/notifications';
import Chats from 'views/chats';
import Services from 'views/services';
import Settings from 'views/settings';
import RightAsideButtons from './RightAsideButtons';
import config from './config';
import RightAsideDrawer from './RightAsideDrawer';
import AsideServiceButtons from '../service-buttons';

type TSetState = (state: IObject) => void;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
  buttons: {
    width: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const useTabStyles = makeStyles({
  root: {
    display: 'none',
  },
});

const panels: Array<ReactElement> = [
  <Notifications />,
  <Chats />,
  <Services />,
  <Settings />,
];

function RightAside(props: IObject): ReactElement {
  const { state, setState, history, onFix, onClose, onClickAway } = props;
  const {
    isOpenRightAside,
    activeTabRightAside,
    isMobile,
    isRightAsideFix,
  } = state;
  const styles = useStyles();
  const tabStyles = useTabStyles();

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <AsideBar direction="right" className={styles.root}>
        <RightAsideDrawer isOpen={isOpenRightAside}>
          <AsideServiceButtons
            isMobile={isMobile}
            isFix={isRightAsideFix}
            onFix={onFix}
            onClose={onClose}
          />
          <Tabs
            panels={panels}
            activeTab={activeTabRightAside}
            tabClasses={tabStyles}
          />
        </RightAsideDrawer>
        <div className={styles.buttons}>
          {config.map((item) => (
            <RightAsideButtons
              data={item.config}
              state={state}
              setState={setState as TSetState}
              history={history}
              key={item.id}
            />
          ))}
        </div>
      </AsideBar>
    </ClickAwayListener>
  );
}

export default RightAside;
