import React, { ReactElement, ComponentType } from 'react';
import { IObject } from 'types/object';

const withProps = <P extends IObject>(initProps: P) => (
  WrappedComponent: ComponentType<P>
) => (props: P): ReactElement => {
  const newProps = { ...props, ...initProps };

  return <WrappedComponent {...newProps} />;
};

export default withProps;
