import React, { ReactElement, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SaveSettingsContext, SettingsContext } from 'contexts/SettingsContext';
import getMixin from 'utils/mixins';
import uniqueId from 'utils/uuid';
import SettingsSwithesItem from './SettingsSwithesItem';
import config from './config';

const useStyles = makeStyles((theme) => ({
  root: {
    ...getMixin('flexColumn', theme),
    alignItems: 'flex-start',
    padding: '24px',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    marginBottom: '24px',
  },
}));

export default function Settings(): ReactElement {
  const setSettings = useContext(SaveSettingsContext);
  const settings = useContext(SettingsContext);
  const data = config(settings) || [];
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Настройки</h3>
      {data.map((properties) => {
        const { onChange } = properties;
        return (
          <SettingsSwithesItem
            {...properties}
            onChange={onChange({ setSettings })}
            key={uniqueId()}
          />
        );
      })}
    </div>
  );
}
