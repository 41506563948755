import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NotesIcon from '@material-ui/icons/Notes';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import SendIcon from '@material-ui/icons/Send';
import SearchIcon from '@material-ui/icons/Search';
import CreateIcon from '@material-ui/icons/Create';
import PersonIcon from '@material-ui/icons/Person';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import GavelIcon from '@material-ui/icons/Gavel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HeadsetIcon from '@material-ui/icons/Headset';
import ContactsIcon from '@material-ui/icons/Contacts';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import DomainIcon from '@material-ui/icons/Domain';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import StarIcon from '@material-ui/icons/Star';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SyncIcon from '@material-ui/icons/Sync';
import DoneIcon from '@material-ui/icons/Done';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import OutlinedFlagIcon from '@material-ui/icons/OutlinedFlag';
import GradeIcon from '@material-ui/icons/Grade';
import ArchiveIcon from '@material-ui/icons/Archive';
import ErrorIcon from '@material-ui/icons/Error';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EmailIcon from '@material-ui/icons/Email';
import InfoIcon from '@material-ui/icons/Info';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import WarningIcon from '@material-ui/icons/Warning';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import EditIcon from '@material-ui/icons/Edit';
import MoneyIcon from '@material-ui/icons/Money'; // 1
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ChatIcon from '@material-ui/icons/Chat';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LinkIcon from '@material-ui/icons/Link';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import TimelineIcon from '@material-ui/icons/Timeline';
import NoteIcon from '@material-ui/icons/Note';
import FolderIcon from '@material-ui/icons/Folder';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SpeedIcon from '@material-ui/icons/Speed';
import HelpIcon from '@material-ui/icons/Help';
import InputIcon from '@material-ui/icons/Input';
import ForumIcon from '@material-ui/icons/Forum';
import SettingsIcon from '@material-ui/icons/Settings';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import Fix from 'components/icons/FixIcon';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import InsertLinkIcon from '@material-ui/icons/InsertLink';

import { IObject } from 'types/object';

const icons: IObject = {
  insertLink: InsertLinkIcon,
  burger: MenuIcon,
  fix: Fix,
  snowflake: AcUnitIcon,
  settings: SettingsIcon,
  forum: ForumIcon,
  input: InputIcon,
  help: HelpIcon,
  speed: SpeedIcon,
  pdf: PictureAsPdfIcon,
  image: ImageIcon,
  description: DescriptionIcon,
  infoOutlined: InfoOutlinedIcon,
  folder: FolderIcon,
  chat: ChatIcon,
  checkCircle: CheckCircleIcon,
  languageIcon: LanguageIcon,
  home: HomeIcon,
  phoneInTalkIcon: PhoneInTalkIcon,
  money: MoneyIcon,
  errorOutline: ErrorOutlineIcon,
  verifiedUser: VerifiedUserIcon,
  playArrow: PlayArrowIcon,
  close: CloseIcon,
  check: CheckIcon,
  cancel: CancelIcon,
  refresh: RefreshIcon,
  add: AddIcon,
  save: SaveIcon,
  angleDown: KeyboardArrowDownIcon,
  angleRight: KeyboardArrowRightIcon,
  angleLeft: KeyboardArrowLeftIcon,
  doneAll: DoneAllIcon,
  thumbUp: ThumbUpIcon,
  thumbDown: ThumbDownIcon,
  moreVert: MoreVertIcon,
  email: EmailIcon,
  phone: PhoneIcon,
  info: InfoIcon,
  addAPhoto: AddAPhotoIcon,
  delete: DeleteIcon,
  lock: LockIcon,
  archive: ArchiveIcon,
  notifications: NotificationsIcon,
  exit: ExitToAppIcon,
  noteAdd: NoteAddIcon,
  domain: DomainIcon,
  pencil: CreateIcon,
  warning: WarningIcon,
  send: SendIcon,
  angleUp: ExpandLessIcon,
  expandMore: ExpandMoreIcon,
  notes: NotesIcon,
  attach: AttachFileIcon,
  chatBubble: ChatBubbleOutlineIcon,
  search: SearchIcon,
  pen: CreateIcon,
  person: PersonIcon,
  addCircleOutline: AddCircleOutlineIcon,
  removeCircleOutline: RemoveCircleOutlineIcon,
  arrowDropDown: ArrowDropDownIcon,
  arrowBack: ArrowBackIcon,
  arrowForward: ArrowForwardIcon,
  shoppingCart: ShoppingCartIcon,
  libraryBooks: LibraryBooksIcon,
  localShipping: LocalShippingIcon,
  star: StarIcon,
  autorenew: AutorenewIcon,
  gavel: GavelIcon,
  sync: SyncIcon,
  done: DoneIcon,
  event: EventIcon,
  schedule: ScheduleIcon,
  outlinedFlag: OutlinedFlagIcon,
  grade: GradeIcon,
  error: ErrorIcon,
  notificationsActive: NotificationsActiveIcon,
  questionAnswer: QuestionAnswerIcon,
  headsetMic: HeadsetMicIcon,
  mobileFriendly: MobileFriendlyIcon,
  edit: EditIcon,
  deleteTrash: DeleteIcon,
  bilets: MoneyIcon,
  offer: AttachMoneyIcon,
  faq: HeadsetIcon,
  contacts: ContactsIcon,
  howToReg: HowToRegIcon,
  cardTravel: CardTravelIcon,
  link: LinkIcon,
  meetingRoom: MeetingRoomIcon,
  fileCopyOutlined: FileCopyOutlinedIcon,
  linkOff: LinkOffIcon,
  clock: AccessTimeIcon,
  menu: MoreVertIcon,
  drag: DragHandleIcon,
  dragIndicator: DragIndicatorIcon,
  clearAll: ClearAllIcon,
  timeLine: TimelineIcon,
  note: NoteIcon,
};

export default icons;
