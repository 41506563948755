import React, { ReactElement } from 'react';
import Switch from '@material-ui/core/Switch';
import Tooltip from 'components/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import getMixin from 'utils/mixins';

interface ISettingsSwithesItemProps {
  name?: string;
  tooltipTitle?: string;
  title?: string;
  defaultStateTitle?: string;
  alterStateTitle?: string;
  checked?: boolean;
  onChange?: (state: boolean, name?: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...getMixin('flexRow', theme),
    fontSize: 12,
    lineHeght: 18,
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('color'),
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.text.primary,
    },
  },
}));

const useSwitchStyles = makeStyles((theme) => ({
  switchBase: {
    '&$checked + $track': {
      backgroundColor: theme.palette.common.black,
    },
  },
  checked: {},
  track: {},
}));

export default function SettingsSwithesItem(
  props: ISettingsSwithesItemProps
): ReactElement {
  const {
    name,
    tooltipTitle = '',
    title,
    defaultStateTitle,
    alterStateTitle,
    checked = false,
    onChange,
  } = props;

  const styles = useStyles();
  const switchStyles = useSwitchStyles();

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (onChange) onChange(event.target.checked, name);
  }

  return (
    <Tooltip title={tooltipTitle} placement="top" enterDelay={500}>
      <div className={styles.root}>
        {`${title}: ${defaultStateTitle} `}
        <Switch
          checked={checked}
          onChange={handleOnChange}
          color="primary"
          classes={switchStyles}
        />
        {` ${alterStateTitle}`}
      </div>
    </Tooltip>
  );
}
