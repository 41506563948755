import { createMuiTheme } from '@material-ui/core';
import type { ITheme, TThemeType } from 'types/theme';
import palette from './palette';
import overrides from './overrides';
import mixins from './mixins';

function createTheme(themeType: TThemeType): ITheme {
  const theme = createMuiTheme({
    palette: {
      type: themeType,
      ...palette,
    },
    mixins,
    overrides,
  });

  return theme as ITheme;
}

export default createTheme;
