import React, { createContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import { getStorage, setStorage } from 'utils/localStorage';
import {
  ISettings,
  ISaveSettingsContext,
  ISettingsProviderProps,
} from 'types/settings';

const defaultSettings: ISettings = {
  isTheme: false,
  isHelp: false,
  isLimitedWidth: false,
};

const SettingsContext = createContext<ISettings>(defaultSettings);
const SaveSettingsContext = createContext<ISaveSettingsContext>(
  () => undefined
);

export const SettingsProvider: FC<ISettingsProviderProps> = ({
  settings,
  children,
}: ISettingsProviderProps) => {
  const [currentSettings, setCurrentSettings] = useState<ISettings>(
    settings || defaultSettings
  );

  const setSettings = (update: ISettings = {}): void => {
    const mergedSettings = { ...currentSettings, ...update };
    setCurrentSettings(mergedSettings);
    setStorage('settings', mergedSettings);
  };

  useEffect(() => {
    const restoredSettings = getStorage('settings');
    if (restoredSettings) setCurrentSettings(restoredSettings);
  }, []);

  return (
    <SaveSettingsContext.Provider value={setSettings}>
      <SettingsContext.Provider value={currentSettings}>
        {children}
      </SettingsContext.Provider>
    </SaveSettingsContext.Provider>
  );
};

export { SettingsContext, SaveSettingsContext };
