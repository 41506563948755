import React, { useContext, useMemo } from 'react';
import type { ReactElement } from 'react';
import { ThemeProvider } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/ru';
import { AuthProvider } from 'contexts/auth-context/AuthContext';
import useTheme from 'hooks/useTheme';
import { BrowserRouter as Router } from 'react-router-dom';
import routes, { renderRoutes } from 'routes';
import GlobalStyles from 'components/GlobalStyles';
import { SettingsContext } from 'contexts/SettingsContext';
import { getThemeType } from 'utils/theme';
import { TThemeType } from 'types/theme';

function App(): ReactElement {
  const { isTheme } = useContext(SettingsContext);
  const theme = useTheme(getThemeType(isTheme) as TThemeType);

  const BrouserRouter = useMemo(() => {
    return <Router>{renderRoutes(routes)}</Router>;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <AuthProvider>
          <GlobalStyles />
          {BrouserRouter}
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
