import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITest {
  test: string;
}

const testSlice = createSlice({
  name: 'tender',
  initialState: {},
  reducers: {
    getTender(state, action: PayloadAction<ITest>) {
      return { ...state, test: action.payload };
    },
  },
});

export const { actions, reducer } = testSlice;
