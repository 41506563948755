import { ITheme, ICustomMixins } from 'types/theme';
import { CSSProperties } from '@material-ui/styles';

function getMixin(name: keyof ICustomMixins, theme: ITheme): CSSProperties {
  const mixin = (theme.mixins as ICustomMixins)[name] as unknown;
  if (typeof mixin === 'function') return mixin(theme) as CSSProperties;

  return (mixin || {}) as CSSProperties;
}

export default getMixin;
