import React, { useState, ReactElement, ComponentType } from 'react';
import { IObject } from 'types/object';

const withState = <P extends IObject>(initState: P) => (
  WrappedComponent: ComponentType<P>
) => (props: P): ReactElement => {
  const [state, setState] = useState(initState);
  const newProps: P = { ...props, ...state, setState };

  return <WrappedComponent {...newProps} />;
};

export default withState;
