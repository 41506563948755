import type { IObject } from 'types/object';

export function getStorage(storageKey: string): IObject {
  const data = localStorage.getItem(storageKey);
  if (data) {
    return JSON.parse(data);
  }
  return {};
}

export function setStorage<K extends string, T>(
  storageKey: K,
  storage: T
): void {
  localStorage.setItem(storageKey, JSON.stringify(storage));
}

export function removeStorage(storageKey: string): void {
  localStorage.removeItem(storageKey);
}
