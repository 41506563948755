import { IObject } from 'types/object';
import ucFirstLetter from 'utils/string';

function toggleHandler(props: IObject, active: boolean): void {
  const { side, setState } = props;
  const asideType = ucFirstLetter(side);

  setState((prevState: IObject) => ({
    ...prevState,
    [`isOpen${asideType}Aside`]: active,
  }));
}

export const handleOnClickAway = (props: IObject) => (): void => {
  const { state, side } = props;
  const asideType = ucFirstLetter(side);
  const { isMobile, [`is${asideType}AsideFix`]: isFix } = state;
  if (!isMobile && !isFix) toggleHandler(props, false);
};

export const handleOnClose = (props: IObject) => (): void => {
  toggleHandler(props, false);
};

export const handleOnOpen = (props: IObject) => (): void => {
  toggleHandler(props, true);
};

export const handleOnFix = (props: IObject) => (): void => {
  const { side, setState } = props;
  const asideType = ucFirstLetter(side);
  const fieldName = `is${asideType}AsideFix`;

  setState((prevState: IObject) => ({
    ...prevState,
    [fieldName]: !prevState[fieldName],
  }));
};
