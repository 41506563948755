import React, { ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { isLightTheme } from 'utils/theme';
import { IObject } from 'types/object';

const useDrawerStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: theme.zIndex.tooltip + 1,
    width: 620,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: () =>
      isLightTheme(theme) ? theme.palette.grey[100] : theme.palette.grey[800],
    [theme.breakpoints.down(760)]: {
      width: '100%',
    },
  },
  paperAnchorLeft: {
    left: 'auto',
    right: 60,
    backgroundColor: () =>
      isLightTheme(theme) ? theme.palette.grey[100] : theme.palette.grey[800],
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: 54,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  open: {
    width: 620,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down(760)]: {
      width: '100%',
    },
  },
  close: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '0 !important',
  },
}));

interface IRightAsideDrawerProps {
  classes?: IObject;
  className?: string;
  isOpen: boolean;
  children?: ReactNode;
}

export default function RightAsideDrawer(
  props: IRightAsideDrawerProps
): ReactElement {
  const { isOpen, children, className, classes } = props;
  const styles = useStyles();
  const drawerStyles = useDrawerStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx(className, {
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
      })}
      classes={{
        ...drawerStyles,
        ...classes,
        paper: clsx({
          [styles.open]: isOpen,
          [styles.close]: !isOpen,
        }),
      }}
    >
      {children}
    </Drawer>
  );
}
