import { ICustomMixins } from 'types/theme';

const mixins: ICustomMixins = {
  gutters: () => {
    return { padding: '24px' };
  },
  toolbar: {},
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  fontCondensed: {
    fontFamily: 'Roboto Condensed, Roboto, Arial, sans-serif',
  },
  iconAroundButton: (theme) => ({
    borderRadius: '50%',
    transition: 'fill .1s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      fill: theme.palette.grey[200],
    },
  }),
};

export default mixins;
