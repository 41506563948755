import {
  PRIMARY_COLOR,
  PRIMARY_LIGHT_COLOR,
  PRIMARY_DARK_COLOR,
  SECONDARY_COLOR,
  SECONDARY_LIGHT_COLOR,
  SECONDARY_DARK_COLOR,
  ERROR_COLOR,
  ERROR_LIGHT_COLOR,
  ERROR_DARK_COLOR,
  INFO_COLOR,
  INFO_LIGHT_COLOR,
  INFO_DARK_COLOR,
  WARNING_COLOR,
  WARNING_LIGHT_COLOR,
  WARNING_DARK_COLOR,
  SUCCESS_COLOR,
  SUCCESS_LIGHT_COLOR,
  SUCCESS_DARK_COLOR,
  PINK_COLOR,
  PINK_LIGHT_COLOR,
  PINK_DARK_COLOR,
  PURPLE_COLOR,
  PURPLE_LIGHT_COLOR,
  PURPLE_DARK_COLOR,
  DEEP_PURPLE_COLOR,
  DEEP_PURPLE_LIGHT_COLOR,
  DEEP_PURPLE_DARK_COLOR,
  INDIGO_COLOR,
  INDIGO_LIGHT_COLOR,
  INDIGO_DARK_COLOR,
  BLUE_COLOR,
  BLUE_LIGHT_COLOR,
  BLUE_DARK_COLOR,
  CYAN_COLOR,
  CYAN_LIGHT_COLOR,
  CYAN_DARK_COLOR,
  TEAL_COLOR,
  TEAL_LIGHT_COLOR,
  TEAL_DARK_COLOR,
  LIGHT_GREEN_COLOR,
  LIGHT_GREEN_LIGHT_COLOR,
  LIGHT_GREEN_DARK_COLOR,
  LIME_COLOR,
  LIME_LIGHT_COLOR,
  LIME_DARK_COLOR,
  YELLOW_COLOR,
  YELLOW_LIGHT_COLOR,
  YELLOW_DARK_COLOR,
  AMBER_COLOR,
  AMBER_LIGHT_COLOR,
  AMBER_DARK_COLOR,
  DEEP_ORANGE_COLOR,
  DEEP_ORANGE_LIGHT_COLOR,
  DEEP_ORANGE_DARK_COLOR,
  BROWN_COLOR,
  BROWN_LIGHT_COLOR,
  BROWN_DARK_COLOR,
  BLUE_GRAY_COLOR,
  BLUE_GRAY_LIGHT_COLOR,
  BLUE_GRAY_DARK_COLOR,
} from 'constants/colors';

export default {
  semitones: {
    background: {
      dark: {
        800: 'rgba(66, 66, 66, .8)',
      },
    },
  },
  primary: {
    light: PRIMARY_LIGHT_COLOR,
    main: PRIMARY_COLOR,
    dark: PRIMARY_DARK_COLOR,
  },
  secondary: {
    light: SECONDARY_LIGHT_COLOR,
    main: SECONDARY_COLOR,
    dark: SECONDARY_DARK_COLOR,
  },
  error: {
    light: ERROR_LIGHT_COLOR,
    main: ERROR_COLOR,
    dark: ERROR_DARK_COLOR,
  },
  warning: {
    light: WARNING_LIGHT_COLOR,
    main: WARNING_COLOR,
    dark: WARNING_DARK_COLOR,
  },
  info: {
    light: INFO_LIGHT_COLOR,
    main: INFO_COLOR,
    dark: INFO_DARK_COLOR,
  },
  success: {
    light: SUCCESS_LIGHT_COLOR,
    main: SUCCESS_COLOR,
    dark: SUCCESS_DARK_COLOR,
  },
  pink: {
    light: PINK_LIGHT_COLOR,
    main: PINK_COLOR,
    dark: PINK_DARK_COLOR,
  },
  purple: {
    light: PURPLE_LIGHT_COLOR,
    main: PURPLE_COLOR,
    dark: PURPLE_DARK_COLOR,
  },
  deeppurple: {
    light: DEEP_PURPLE_LIGHT_COLOR,
    main: DEEP_PURPLE_COLOR,
    dark: DEEP_PURPLE_DARK_COLOR,
  },
  indigo: {
    light: INDIGO_LIGHT_COLOR,
    main: INDIGO_COLOR,
    dark: INDIGO_DARK_COLOR,
  },
  blue: {
    light: BLUE_LIGHT_COLOR,
    main: BLUE_COLOR,
    dark: BLUE_DARK_COLOR,
  },
  cyan: {
    light: CYAN_LIGHT_COLOR,
    main: CYAN_COLOR,
    dark: CYAN_DARK_COLOR,
  },
  teal: {
    light: TEAL_LIGHT_COLOR,
    main: TEAL_COLOR,
    dark: TEAL_DARK_COLOR,
  },
  lightgreen: {
    light: LIGHT_GREEN_LIGHT_COLOR,
    main: LIGHT_GREEN_COLOR,
    dark: LIGHT_GREEN_DARK_COLOR,
  },
  lime: {
    light: LIME_LIGHT_COLOR,
    main: LIME_COLOR,
    dark: LIME_DARK_COLOR,
  },
  yellow: {
    light: YELLOW_LIGHT_COLOR,
    main: YELLOW_COLOR,
    dark: YELLOW_DARK_COLOR,
  },
  amber: {
    light: AMBER_LIGHT_COLOR,
    main: AMBER_COLOR,
    dark: AMBER_DARK_COLOR,
  },
  deeporange: {
    light: DEEP_ORANGE_LIGHT_COLOR,
    main: DEEP_ORANGE_COLOR,
    dark: DEEP_ORANGE_DARK_COLOR,
  },
  brown: {
    light: BROWN_LIGHT_COLOR,
    main: BROWN_COLOR,
    dark: BROWN_DARK_COLOR,
  },
  bluegray: {
    light: BLUE_GRAY_LIGHT_COLOR,
    main: BLUE_GRAY_COLOR,
    dark: BLUE_GRAY_DARK_COLOR,
  },
};
