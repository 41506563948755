import React, { ReactNode, ReactElement } from 'react';

interface ITabPanelProps {
  children: ReactNode;
  activeTabIndex: number;
  index: number;
  className?: string;
}

export default function TabPanel(props: ITabPanelProps): ReactElement {
  const { children, activeTabIndex, index, className = '', ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={activeTabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
      {...other}
    >
      {activeTabIndex === index && children}
    </div>
  );
}
