import React, { createContext, FC, useState } from 'react';
import { IGlobalState, IChangeGlobalState } from 'types/globalState';
import { TChildrenProps } from 'types/childrenProp';

const initGlobalState: IGlobalState = {
  isMobile: true,
  isOpenRightAside: false,
  activeTabRightAside: 0,
  isRightAsideFix: false,
  isLeftAsideFix: false,
  isOpenLeftAside: false,
};

interface IGlobalProvider {
  globalState?: IGlobalState;
  children: TChildrenProps;
}

export const GlobalStateConext = createContext<IGlobalState>(initGlobalState);

export const ChangeGlobalStateContext = createContext<IChangeGlobalState>(
  () => undefined
);

export const GlobalProvider: FC<IGlobalProvider> = ({
  children,
  globalState,
}) => {
  const [curGlobalState, setCurGlobalState] = useState<IGlobalState>(
    globalState || initGlobalState
  );

  return (
    <ChangeGlobalStateContext.Provider value={setCurGlobalState}>
      <GlobalStateConext.Provider value={curGlobalState}>
        {children}
      </GlobalStateConext.Provider>
    </ChangeGlobalStateContext.Provider>
  );
};
