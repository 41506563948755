export const PRIMARY_COLOR = '#20A04B';
export const PRIMARY_LIGHT_COLOR = '#66BB6A';
export const PRIMARY_DARK_COLOR = '#2E7D32';

export const SECONDARY_COLOR = '#1E88E5';
export const SECONDARY_LIGHT_COLOR = '#42A5F5';
export const SECONDARY_DARK_COLOR = '#1565C0';

export const ERROR_COLOR = '#E53935';
export const ERROR_LIGHT_COLOR = '#EF5350';
export const ERROR_DARK_COLOR = '#C62828';

export const WARNING_COLOR = '#FB8C00';
export const WARNING_LIGHT_COLOR = '#FFA726';
export const WARNING_DARK_COLOR = '#EF6C00';

export const INFO_COLOR = '#039BE5';
export const INFO_LIGHT_COLOR = '#29B6F6';
export const INFO_DARK_COLOR = '#0277BD';

export const SUCCESS_COLOR = '#20A04B';
export const SUCCESS_LIGHT_COLOR = '#66BB6A';
export const SUCCESS_DARK_COLOR = '#2E7D32';

export const PINK_COLOR = '#D81B60';
export const PINK_LIGHT_COLOR = '#EC407A';
export const PINK_DARK_COLOR = '#AD1457';

export const PURPLE_COLOR = '#8E24AA';
export const PURPLE_LIGHT_COLOR = '#8E24AA';
export const PURPLE_DARK_COLOR = '#6A1B9A';

export const DEEP_PURPLE_COLOR = '#5E35B1';
export const DEEP_PURPLE_LIGHT_COLOR = '#7E57C2';
export const DEEP_PURPLE_DARK_COLOR = '#4527A0';

export const INDIGO_COLOR = '#3949AB';
export const INDIGO_LIGHT_COLOR = '#5C6BC0';
export const INDIGO_DARK_COLOR = '#283593';

export const BLUE_COLOR = '#039BE5';
export const BLUE_LIGHT_COLOR = '#29B6F6';
export const BLUE_DARK_COLOR = '#0277BD';

export const CYAN_COLOR = '#00ACC1';
export const CYAN_LIGHT_COLOR = '#26C6DA';
export const CYAN_DARK_COLOR = '#00838F';

export const TEAL_COLOR = '#00897B';
export const TEAL_LIGHT_COLOR = '#26A69A';
export const TEAL_DARK_COLOR = '#00695C';

export const LIGHT_GREEN_COLOR = '#7CB342';
export const LIGHT_GREEN_LIGHT_COLOR = '#9CCC65';
export const LIGHT_GREEN_DARK_COLOR = '#558B2F';

export const LIME_COLOR = '#C0CA33';
export const LIME_LIGHT_COLOR = '#D4E157';
export const LIME_DARK_COLOR = '#9E9D24';

export const YELLOW_COLOR = '#FDD835';
export const YELLOW_LIGHT_COLOR = '#FFEE58';
export const YELLOW_DARK_COLOR = '#F9A825';

export const AMBER_COLOR = '#FFB300';
export const AMBER_LIGHT_COLOR = '#FFCA28';
export const AMBER_DARK_COLOR = '#FF8F00';

export const DEEP_ORANGE_COLOR = '#F4511E';
export const DEEP_ORANGE_LIGHT_COLOR = '#F4511E';
export const DEEP_ORANGE_DARK_COLOR = '#D84315';

export const BROWN_COLOR = '#6D4C41';
export const BROWN_LIGHT_COLOR = '#8D6E63';
export const BROWN_DARK_COLOR = '#4E342E';

export const GREY_COLOR = '#BDBDBD';
export const GREY_LIGHT_COLOR = '#757575';
export const GREY_DARK_COLOR = '#424242';

export const BLUE_GRAY_COLOR = '#546E7A';
export const BLUE_GRAY_LIGHT_COLOR = '#78909C';
export const BLUE_GRAY_DARK_COLOR = '#37474F';
