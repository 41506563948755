import { PRIMARY_COLOR } from 'constants/colors';

export default {
  MuiPickersDatePickerRoot: {
    dateLandscape: {
      marginRight: 0,
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: PRIMARY_COLOR,
    },
  },
  MuiPickersYear: {
    root: {
      '&:focus': {
        color: PRIMARY_COLOR,
      },
    },
    yearSelected: {
      color: PRIMARY_COLOR,
    },
  },
  MuiPickersDay: {
    current: {
      color: PRIMARY_COLOR,
    },
    daySelected: {
      backgroundColor: PRIMARY_COLOR,
      color: '#fff',
      '&:hover': {
        backgroundColor: PRIMARY_COLOR,
      },
    },
  },

  MuiButton: {
    label: {
      '& > h4': {
        fontSize: '1rem',
      },
    },
  },
};
