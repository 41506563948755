import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { TChildrenProps } from 'types/childrenProp';
import { ACCOUNT } from 'constants/routes';
import useAuth from '../hooks/useAuth';

export default function GuestGuard({ children }: TChildrenProps): ReactElement {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to={ACCOUNT} />;
  }

  return <>{children}</>;
}
