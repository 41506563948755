import React, { ReactElement, ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { IObject } from 'types/object';
import { TTransition } from 'types/transition';

type TClasses = {
  root?: string;
  active?: string;
};

type TTransitionsProps = string | Array<TTransition> | undefined;

type TDirection = 'hrt' | 'vrt';

interface ICollapse extends IObject {
  isOpen: boolean;
  minSize?: number;
  maxSize?: number;
  children: ReactNode | Array<ReactNode>;
  timeout?: number;
  direction?: TDirection;
  classes?: TClasses;
  minSizeUnit?: string;
  maxSizeUnit?: string;
  transitionProps?: TTransitionsProps;
  childrenRender?: (params: IObject) => {};
  appear?: boolean;
  other?: IObject;
}

interface IStylesProps {
  direction?: TDirection;
  minSize?: number;
  maxSize?: number;
  duration?: number;
  minSizeUnit?: string;
  maxSizeUnit?: string;
}

const useStyles = makeStyles({
  root: {
    maxWidth: ({ direction, maxSize, maxSizeUnit }: IStylesProps) =>
      !isVrtDirection(direction as TDirection) && maxSize
        ? `${maxSize}${maxSizeUnit}`
        : 'none',
    maxHeight: ({ direction, maxSize, maxSizeUnit }: IStylesProps) =>
      isVrtDirection(direction as TDirection) && maxSize
        ? `${maxSize}${maxSizeUnit}`
        : 'none',
    minWidth: ({ direction, minSize, minSizeUnit }: IStylesProps) =>
      !isVrtDirection(direction as TDirection)
        ? `${minSize}${minSizeUnit}`
        : '0px',
    minHeght: ({ direction, minSize, minSizeUnit }: IStylesProps) =>
      isVrtDirection(direction as TDirection)
        ? `${minSize}${minSizeUnit}`
        : '0px',
    overflow: 'hidden',
  },
  entered: {
    overflow: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
});

function isVrtDirection(direction: TDirection) {
  return direction === 'vrt';
}

function difineCollapseType(direction: TDirection): string {
  return isVrtDirection(direction) ? 'height' : 'width';
}

function joinTransitionsProperties(transitions: Array<IObject>) {
  return transitions
    .map((property) => {
      const { name } = property;
      const delay: string = property.delay ? `${property.delay}ms` : '0ms';
      const easeFunc: string = property.easeFunc || 'ease';
      const duration = `${property.duration}ms`;

      return `${name} ${duration} ${easeFunc} ${delay}`;
    })
    .join(', ');
}

function getTransitions(
  duration: number,
  transitionProps: TTransitionsProps
): string {
  const transitions = Array.isArray(transitionProps)
    ? joinTransitionsProperties(transitionProps)
    : `${transitionProps} ${duration}ms ease-in-out`;
  return `, ${transitions}`;
}

function defaultStyle(
  direction: TDirection,
  duration: number,
  transitionProps: TTransitionsProps
): object {
  const transitions = transitionProps
    ? getTransitions(duration, transitionProps)
    : '';

  return {
    transition: `${difineCollapseType(
      direction
    )} ${duration}ms ease-in-out ${transitions}`,
  };
}

function transitionStyles(
  direction: TDirection,
  minSize: number,
  maxSize: number,
  minSizeUnit: string,
  maxSizeUnit: string
): IObject {
  const collapseType = difineCollapseType(direction);

  return {
    entering: { [collapseType]: `${maxSize}${maxSizeUnit}` },
    entered: { [collapseType]: `${maxSize}${maxSizeUnit}` },
    exiting: { [collapseType]: `${minSize}${minSizeUnit}` },
    exited: { [collapseType]: `${minSize}${minSizeUnit}` },
  };
}

export default function Collapse(props: ICollapse): ReactElement {
  const {
    direction = 'hrt',
    maxSize = 0,
    minSize = 0,
    isOpen = false,
    children,
    timeout = 300,
    classes = {},
    transitionProps,
    minSizeUnit = 'px',
    maxSizeUnit = 'px',
    ...other
  } = props;

  const nodeRef = React.useRef(null);
  const styles = useStyles({
    direction,
    maxSize,
    minSize,
    minSizeUnit,
    maxSizeUnit,
  });

  const tStyles = transitionStyles(
    direction,
    minSize,
    maxSize,
    minSizeUnit,
    maxSizeUnit
  );
  const dStyles = defaultStyle(direction, timeout, transitionProps);

  return (
    <Transition nodeRef={nodeRef} in={isOpen} timeout={timeout} {...other}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{ ...dStyles, ...tStyles[state] }}
          className={clsx(styles.root, { active: isOpen }, classes.root, {
            [styles.entered]: state === 'entered',
            [styles.hidden]:
              state === 'exited' && isOpen === false && minSize === 0,
          })}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}
