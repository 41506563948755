import { LOGIN_URL, REGISTER_URL } from 'constants/urls';
import type { AxiosRequestConfig } from 'axios';

const login: AxiosRequestConfig = {
  method: 'post',
  url: LOGIN_URL,
  params: {
    email: '',
    password: '',
  },
};

const register: AxiosRequestConfig = {
  method: 'post',
  url: REGISTER_URL,
  data: {
    email: '',
    name: '',
    password: '',
  },
};

export { login, register };
