import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from 'components/IconButton';
import getMixin from 'utils/mixins';

type TAsideServiceButtonsProps = {
  isFix: boolean;
  isMobile: boolean;
  isCloseVisible?: boolean;
  onFix: () => void | undefined;
  onClose: () => void | undefined;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 24,
    right: 16,
    ...getMixin('flexRow', theme),
    '& button': {
      marginRight: 10,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const useFixBtnStyles = makeStyles((theme) => ({
  root: {
    '&.fix': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function AsideServiceButtons(
  props: TAsideServiceButtonsProps
): ReactElement {
  const { isMobile, isCloseVisible = true, isFix, onFix, onClose } = props;
  const styles = useStyles();
  const fixBtnStyles = useFixBtnStyles();

  return (
    <div className={styles.root}>
      {!isMobile && (
        <IconButton
          icon="fix"
          classes={fixBtnStyles}
          className={clsx({ fix: isFix })}
          size="small"
          onClick={onFix}
        />
      )}

      {isCloseVisible && (
        <IconButton icon="close" size="small" onClick={onClose} />
      )}
    </div>
  );
}
