import React, { ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { isLightTheme } from 'utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: ({ direction, minSize }: IStylesProps) =>
      direction === 'top' || direction === 'bottom' ? '100%' : `${minSize}px`,
    minHeight: ({ direction, minSize }: IStylesProps) =>
      direction === 'right' || direction === 'left' ? '100vh' : `${minSize}px`,
    backgroundColor: () =>
      isLightTheme(theme) ? theme.palette.grey[100] : theme.palette.grey[800],
    borderRadius: 0,
    position: 'fixed',
    top: ({ direction }: IStylesProps) => (direction === 'bottom' ? 'auto' : 0),
    bottom: ({ direction }: IStylesProps) => (direction === 'top' ? 'auto' : 0),
    left: ({ direction }: IStylesProps) => (direction === 'right' ? 'auto' : 0),
    right: ({ direction }: IStylesProps) => (direction === 'left' ? 'auto' : 0),
  },
}));

interface IAsideBar {
  direction?: string;
  children?: ReactNode;
  minSize?: number;
  className?: string;
}

interface IStylesProps {
  direction?: string;
  minSize?: number;
}

const AsideBar = React.forwardRef(
  // eslint-disable-next-line
  (props: IAsideBar, ref: any): ReactElement => {
    const {
      direction,
      children,
      minSize = 60,
      className = '',
      ...other
    } = props;
    const styles = useStyles({ direction, minSize });

    return (
      <div id="aside-bar" ref={ref} {...other}>
        <Box boxShadow={5} className={clsx(styles.root, className)}>
          {children}
        </Box>
      </div>
    );
  }
);

export default AsideBar;
