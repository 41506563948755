import { IObject } from 'types/object';
import { ISettings } from 'types/settings';

const handleOnChange = (props: IObject) => (
  state: boolean,
  name: string
): void => {
  const { setSettings } = props;
  setSettings({ [name]: state });
};

export default function config(params: ISettings): Array<IObject> {
  const { isTheme, isHelp, isLimitedWidth } = params;
  return [
    {
      name: 'isTheme',
      tooltipTitle: 'Режим дневной темы сайта',
      title: 'Тема',
      defaultStateTitle: 'День',
      alterStateTitle: 'Ночь',
      checked: isTheme,
      onChange: handleOnChange,
    },
    {
      name: 'isHelp',
      tooltipTitle: 'Отображение полной справачной информации',
      title: 'Справка',
      defaultStateTitle: 'Новичок',
      alterStateTitle: 'Эксперт',
      checked: isHelp,
      onChange: handleOnChange,
    },
    {
      name: 'isLimitedWidth',
      tooltipTitle: 'Режим с фиксированной шириной рабочей области',
      title: 'Ширина',
      defaultStateTitle: 'Ограниченная',
      alterStateTitle: 'Весь экран',
      checked: isLimitedWidth,
      onChange: handleOnChange,
    },
  ];
}
