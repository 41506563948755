import { useContext } from 'react';
import {
  GlobalStateConext,
  ChangeGlobalStateContext,
} from 'contexts/GlobalContext';
import { IGlobalContext } from 'types/globalState';

const useGlobalState = (): IGlobalContext => {
  return {
    globalState: useContext(GlobalStateConext),
    setGlobalState: useContext(ChangeGlobalStateContext),
  };
};

export default useGlobalState;
