import { useEffect, useState } from 'react';

type TClientSize = {
  width: number;
  height: number;
};

const initSize = {
  width: -1,
  height: -1,
};

export default function useWindowSize(): TClientSize {
  const [clientSize, setClientSize] = useState<TClientSize>(initSize);

  function getSize(): void {
    setTimeout(() => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setClientSize({ width, height });
    }, 100);
  }

  useEffect(() => {
    getSize();
    window.addEventListener('resize', getSize);

    return () => {
      return window.removeEventListener('resize', getSize);
    };
  }, []);

  return clientSize;
}
