import React, {
  ReactElement,
  ComponentType,
  useEffect,
  EffectCallback,
  DependencyList,
} from 'react';
import { IObject } from 'types/object';

type TEffectProp = (props: IObject) => EffectCallback;
type TDeptsProp = (props: IObject) => DependencyList;

const withEffect = (effect: TEffectProp, depts: TDeptsProp) => (
  WrappedComponent: ComponentType<IObject>
) => (props: IObject): ReactElement => {
  useEffect(
    () => {
      effect(props);
    },
    // eslint-disable-next-line
    depts(props));

  return <WrappedComponent {...props} />;
};

export default withEffect;
