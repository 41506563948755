import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'layouts/main-layout';
// import AuthGuard from 'components/AuthGuard';
import GuestGuard from 'components/GuestGuard';
import uniqueId from 'utils/uuid';
import {
  SIGN_IN,
  MAIN,
  DASHBOARD,
  PURCHASE_TENDER,
  SALE_TENDER,
} from 'constants/routes';
// import Test from 'components/Test';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any; // eslint-disable-line
  layout?: any; // eslint-disable-line
  component?: any; // eslint-disable-line
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<span>Loading ...</span>}>
    <Switch>
      {routes.map((route) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={uniqueId()}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: SIGN_IN,
    component: lazy(() => import('views/auth')),
  },
  {
    path: MAIN,
    // guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: DASHBOARD,
        component: lazy(() => import('views/dashboard')),
      },
      {
        exact: true,
        path: PURCHASE_TENDER,
        component: lazy(() => import('views/purchase-tender')),
      },
      {
        exact: true,
        path: SALE_TENDER,
        component: lazy(() => import('views/sale-tender')),
      },
      {
        exact: true,
        path: SALE_TENDER,
        component: lazy(() => import('views/sale-tender')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
