import withState from './withState';
import withMemo from './withMemo';
import withProps from './withProps';
import withHandlers from './withHandlers';
import withEffect from './withEffect';

// eslint-disable-next-line
const compose = (...funcs: Array<any>): any =>
  funcs.reduce(
    // eslint-disable-next-line
    (a, b) => (...args: any) => a(b(...args)),
    // eslint-disable-next-line
    (arg: any) => arg
  );

export { compose, withState, withMemo, withProps, withHandlers, withEffect };
