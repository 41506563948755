import React, { createContext, ReactElement, useReducer } from 'react';
import type { IAuthState, IAuthContextValue } from 'types/auth';
import type { TChildrenProps } from 'types/childrenProp';
import type { AxiosInstance } from 'axios';
import { setStorage, removeStorage } from 'utils/localStorage';
import reducer from 'contexts/auth-context/reducers';
import { axiosInstance, axiosRequest } from 'utils/axios';
import {
  login as loginEndPoint,
  register as registerEndPoint,
} from 'endpoints/auth';

const setSession = (axios: AxiosInstance, accessToken: string | null): void => {
  if (accessToken) {
    setStorage('accessToken', accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    removeStorage('accessToken');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const initialIAuthState: IAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  method: 'JWT',
};

const AuthContext = createContext<IAuthState>({
  ...initialIAuthState,
});

const AuthMethodtsContext = createContext<IAuthContextValue>({
  login: () => Promise.resolve(),
  logout: () => undefined,
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }: TChildrenProps): ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialIAuthState);

  const login = async (email: string, password: string) => {
    const data = {
      ...loginEndPoint,
      data: { email, password },
    };
    const response = await axiosRequest(data);

    if (response) {
      const { accessToken, user } = response.data;

      setSession(axiosInstance, accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }
  };

  const logout = () => {
    setSession(axiosInstance, null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string) => {
    const response = await axiosRequest({
      ...registerEndPoint,
      data: { email, name, password },
    });
    const { accessToken, user } = response.data;

    setStorage('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  return (
    <AuthMethodtsContext.Provider value={{ login, logout, register }}>
      <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
    </AuthMethodtsContext.Provider>
  );
};

export { AuthContext, AuthMethodtsContext };
