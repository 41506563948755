import React, { ReactElement, useContext, useEffect } from 'react';
import { TChildrenProps } from 'types/childrenProp';
import AppBackground from 'components/AppBackground';
import LeftAside from 'views/asides/left';
import RightAside from 'views/asides/right';
import Navbar from 'views/navbar';
import { makeStyles } from '@material-ui/core/styles';
import { SettingsContext } from 'contexts/SettingsContext';
import getMixin from 'utils/mixins';
import useGlobalState from 'hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { compose, withHandlers, withProps } from 'hocs';
import {
  handleOnClose,
  handleOnFix,
  handleOnClickAway,
  handleOnOpen,
} from 'views/asides/handlers';

interface IMainLayout {
  title?: string;
  children?: TChildrenProps;
}

interface IStylesProps {
  isLimitedWidth?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...getMixin('flexColumn', theme),
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 15px 15px 15px',
    },
  },
  container: {
    maxWidth: ({ isLimitedWidth }: IStylesProps) =>
      !isLimitedWidth ? '1140px' : 'none',
    width: '100%',
    height: '100%',
    backgroundColor: 'grey',
    transition: 'all',
  },
}));

const rightAsideHandlers = {
  onFix: handleOnFix,
  onClose: handleOnClose,
  onClickAway: handleOnClickAway,
};

const navbarHandlers = {
  onOpenMenu: handleOnOpen,
};

const RightAsidePanel: any = withHandlers(rightAsideHandlers)(RightAside); // eslint-disable-line
const NavbarPanel: any = compose( // eslint-disable-line
  withProps({ side: 'left' }),
  withHandlers(navbarHandlers)
)(Navbar);

export default function MainLayout(props: IMainLayout): ReactElement {
  const { title = 'Панель управления', children } = props;
  const { isLimitedWidth } = useContext(SettingsContext);
  const styles = useStyles({ isLimitedWidth });
  const history = useHistory();
  const { globalState, setGlobalState } = useGlobalState();
  const { width } = useWindowSize();

  useEffect(() => {
    if (width > 0) {
      if (width < 960) {
        setGlobalState({ ...globalState, isMobile: true });
      } else {
        setGlobalState({ ...globalState, isMobile: false });
      }
    }
  }, [width]);

  return (
    <AppBackground className={styles.root}>
      <RightAsidePanel
        state={globalState}
        setState={setGlobalState}
        history={history}
        side="right"
      />
      <LeftAside
        side="left"
        globalState={globalState}
        setGlobalState={setGlobalState}
      />
      <NavbarPanel state={globalState} setState={setGlobalState} />

      <div className={styles.container}>
        <h2>{title}</h2>
        {children}
      </div>
    </AppBackground>
  );
}
