import { ITreeNode } from 'types/treeNode';
import { IObject } from 'types/object';

type TInitTreeFunc = {
  (node: ITreeNode): IObject;
};

export function traversalTree(
  tree: Array<ITreeNode>,
  callback: (
    node: ITreeNode,
    indexNode?: number,
    treeData?: Array<ITreeNode>
  ) => void,
  index: number = 0
): void {
  if (callback) {
    let currIndex = index;
    tree.forEach((node) => {
      callback(node, currIndex, tree);
      currIndex += 1;
      if (node.children) traversalTree(node.children, callback, currIndex);
    });
  }
}

export function findNode(
  tree: Array<ITreeNode>,
  callback: (
    node: ITreeNode,
    indexNode?: number,
    treeData?: Array<ITreeNode>
  ) => boolean,
  index: number = 0
): ITreeNode | null {
  if (!callback) return null;

  let currIndex = index;
  let result = null;
  // eslint-disable-next-line
  for (const node of tree) {
    if (callback(node, currIndex, tree)) {
      result = node;
      break;
    }
    currIndex += 1;
    if (node.children) {
      result = findNode(node.children, callback, index);
    }
  }

  return result;
}

export function fillNode(node: ITreeNode | null, values: IObject): void {
  if (node) {
    // eslint-disable-next-line
    Object.entries(values).forEach(([key, value]: any[]) => {
      /* eslint-disable no-param-reassign */
      node[key] = value;
    });
  }
}

export function setValuesToChildren(
  tree: Array<ITreeNode>,
  values: IObject
): void {
  tree.forEach((node) => {
    if (node.wrapper) {
      setValuesToChildren(node.children as ITreeNode[], values);
    }
    fillNode(node, values);
  });
}

export function initTree(
  tree: Array<ITreeNode>,
  initValues: IObject | TInitTreeFunc,
  parent?: ITreeNode | null
): void {
  tree.forEach((node) => {
    const initNodeValues =
      typeof initValues === 'function' ? initValues(node) : initValues;

    if (node.children) {
      initTree(node.children, initValues, node);
    }
    fillNode(node, { ...initNodeValues, parent });
  });
}
