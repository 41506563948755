import {
  AMBER_COLOR,
  CYAN_COLOR,
  BLUE_COLOR,
  ERROR_COLOR,
  TEAL_COLOR,
} from 'constants/colors';
import { PURCHASE_TENDER, SALE_TENDER } from 'constants/routes';
import { IObject } from 'types/object';
import {
  handleOnClickInfoMenuItem,
  handleOnClickTradeMenuItem,
} from './handlers';

export interface ITabData extends IObject {
  id: number;
  icon: string;
  iconHoverColor: string;
  onClick: (props: IObject) => () => void;
}

export const tabIcons: Array<ITabData> = [
  {
    id: 1,
    icon: 'notifications',
    iconHoverColor: AMBER_COLOR,
    onClick: (props: IObject) => (): void => {
      handleOnClickInfoMenuItem(props, 0);
    },
  },

  {
    id: 2,
    icon: 'forum',
    iconHoverColor: CYAN_COLOR,
    onClick: (props: IObject) => (): void => {
      handleOnClickInfoMenuItem(props, 1);
    },
  },

  {
    id: 3,
    icon: 'headsetMic',
    iconHoverColor: BLUE_COLOR,
    onClick: (props: IObject) => (): void => {
      handleOnClickInfoMenuItem(props, 2);
    },
  },
  {
    id: 4,
    icon: 'settings',
    iconHoverColor: TEAL_COLOR,
    onClick: (props: IObject) => (): void => {
      handleOnClickInfoMenuItem(props, 3);
    },
  },
];

const tradeIcon: Array<ITabData> = [
  {
    id: 5,
    icon: 'offer',
    iconHoverColor: BLUE_COLOR,
    onClick: (props: IObject) => (): void => {
      handleOnClickTradeMenuItem(props, PURCHASE_TENDER);
    },
  },
  {
    id: 6,
    icon: 'shoppingCart',
    iconHoverColor: ERROR_COLOR,
    onClick: (props: IObject) => (): void => {
      handleOnClickTradeMenuItem(props, SALE_TENDER);
    },
  },
];

export default [
  {
    id: 1,
    config: tabIcons,
  },
  {
    id: 2,
    config: tradeIcon,
  },
];
