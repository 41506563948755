import React, { ReactElement, useContext } from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SettingsContext } from 'contexts/SettingsContext';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: '7px 12px',
    fontSize: '12px',
    textAlign: 'left',
    boxShadow:
      '0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12)',
    minWidth: 'fit-content',
  },
  arrow: {
    color: theme.palette.background.paper,
  },
}));

export default function TentorgTooltip(props: TooltipProps): ReactElement {
  const { classes = {}, title, children, ...other } = props;
  const styles = useStyles();
  const { isHelp } = useContext(SettingsContext);

  return isHelp ? (
    <Tooltip
      {...other}
      arrow
      title={title}
      classes={{ tooltip: styles.tooltip, arrow: styles.arrow, ...classes }}
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}
