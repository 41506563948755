import React, { ReactElement } from 'react';
import icons from 'constants/icons';
import { IObject } from 'types/object';

function getIcon(type: string, props?: IObject): ReactElement | null {
  const icon = icons[type];
  return icon ? React.createElement(icon, props) : null;
}

export default getIcon;
